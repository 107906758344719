$color-primary: #f1a417;
$color-secondary: #2f7096;

$text-color-primary: #333333;
$text-color-secondary: #707070;
$danger: #f44235;
$success: #4aaf4f;
$info: #01a8f4;
$warning: #ffc107;

:root {
  --primary: #f1a417;
  --secondary: #2f7096;
  --textPrimary: #333;
  --textSecondary: #707070;
  --danger: #f44235;
  --success: #4aaf4f;
  --info: #01a8f4;
  --warning: #ffd700;
  --white: #fff;
  --offWhite: #f7f7f7;
  --black: #000;
  --dataPickerBorderColor: #f1a417;
  --whatsapp: #25d366;
}